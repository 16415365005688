<template>
  <div class="main-container">
    <v-row no-gutters align="center" style="margin-bottom:0px">
      <v-card
        class="mx-auto invisible-border-card"
        width="100%"
        max-width="600"
      >
        <v-toolbar color="cyan" dark>
          <v-icon>mdi-bell</v-icon>

          <v-toolbar-title>推播訊息</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-text style="padding-bottom:0">
          <v-row style="height:80px">
            <v-col>
              <v-dialog
                ref="dialog"
                v-model="modal"
                :return-value.sync="date"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="date"
                    label="選擇查看日期"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="date"
                  locale="zh-tw"
                  :min="startDate"
                  :max="nowDate"
                  scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="modal = false">
                    取消
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="
                      $refs.dialog.save(date);
                      initFirestoreListener();
                    "
                  >
                    確認
                  </v-btn>
                </v-date-picker>
              </v-dialog>
            </v-col>
            <!-- <v-col>
              <v-checkbox
                v-model="isChecked"
                label="按時間排序"
                @change="initFirestoreListener"
              ></v-checkbox>
            </v-col> -->
          </v-row>
          <!-- <v-dialog
            ref="dialog"
            v-model="modal"
            :return-value.sync="date"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date"
                label="選擇查看日期"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="date"
              locale="zh-tw"
              :min="startDate"
              :max="nowDate"
              scrollable
            >
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="modal = false">
                取消
              </v-btn>
              <v-btn
                text
                color="primary"
                @click="
                  $refs.dialog.save(date);
                  initFirestoreListener();
                "
              >
                確認
              </v-btn>
            </v-date-picker>
          </v-dialog> -->
          <v-text-field
            v-model="searchString"
            placeholder="搜尋關鍵字"
            @keydown.enter.prevent="search"
          >
            <v-icon slot="append" color="green" @click="search">
              mdi-magnify
            </v-icon>
          </v-text-field>
        </v-card-text>
      </v-card>
    </v-row>
    <div class="list-container" ref="scrollContainer">
      <!--- MAIN content -->
      <v-card
        class="mx-auto invisible-border-card"
        width="100%"
        max-width="600"
      >
        <v-card-text style="padding-top:0">
          <v-list three-line>
            <template v-for="(item, index) in filteredMessages">
              <v-list-item
                :key="item.ts"
                :ref="`listItem_${index}`"
                :data-index="index"
              >
                <!-- <v-list-item-avatar>
                  <v-img :src="item.avatar"></v-img>
                </v-list-item-avatar> -->
                <v-list-item-content
                  class="collapsible-content"
                  :class="{ expanded: item.expanded }"
                >
                  <v-list-item-title >
                    <v-row class="d-flex">
                      <v-col cols="9" class="normal-wrap">
                        <p style="font-weight: bold;"> {{ item.title }} </p>
                      </v-col>
                      <!-- <span class="text-truncate" style="font-weight: bold;"> {{ item.title }} </span> -->
                      <v-col cols="3" class="text-right">
                        <span>
                        {{ convertToDateTime(item.ts) }}
                        <!-- <v-icon v-if="!item.read" color="red">mdi-new-box</v-icon>
                        <button @click="item.expanded = !item.expanded">
                          <v-icon v-if="item.expanded">mdi-chevron-up</v-icon>
                          <v-icon v-else>mdi-chevron-down</v-icon>
                        </button> -->
                      </span>
                      </v-col>
                    </v-row>
                    
                    
                  </v-list-item-title>
                  <!--
                  <v-list-item-subtitle class="mb-1 opacity-100">{{ convertToDateTime(item.ts) }}</v-list-item-subtitle>
                  -->
                  <div v-html="item.text"></div>
                  <br />
                </v-list-item-content>
              </v-list-item>
              <v-divider :key="index"></v-divider>
            </template>
          </v-list>
        </v-card-text>
      </v-card>

      <!-- Alert snackbar - new incoming orders (manual mode only) -->
      <!-- <v-snackbar top rounded="pill" color="purple darken-2" :timeout="-1"
          v-model="snackbar.new.show">
          您有 {{ newOrdersAlert.newOrdersCount }} 筆新進單，請及時處理～
          <template v-slot:action="{ attrs }">
            <v-btn dark text v-bind="attrs" @click="onNewOrderAlertDismissed">Close</v-btn>
          </template>
        </v-snackbar> -->
    </div>

    <!-- Bottom navigation (tabs) -->
    <v-bottom-navigation
      fixed
      dark
      :color="themeColor"
      class="bottom-toolbar d-flex justify-space-between"
    >
      <div class="d-flex align-center"></div>

      <div
        class="title text-center font-weight-black grey--text text--lighten-2 d-flex align-center"
      >
        {{ shopName }}
      </div>
    </v-bottom-navigation>

    <!-- <v-btn
      v-if="isOverflow"
      fab
      @click="onFabClick"
      class="fab-button transparent-btn"
    >
      <v-icon>mdi-arrow-down</v-icon>
    </v-btn> -->
    <!-- Action Menu -->
    <PosActionMenu
      @search="search"
      :showSearchBtn="false"
      :showMessageBtn="false"
      :showHomeBtn="true"
      :signout="signout"
    >
    </PosActionMenu>
    <!-- Floating Action Button (FAB) Fixed at Bottom -->
  </div>
</template>

<script>
import { air3Firestore, FirestoreDataEvent } from "../firebase";

import PosActionMenu from "../components/PosActionMenu";

import moment from "moment";

export default {
  name: "PushMessage",
  components: {
    PosActionMenu,
  },

  mounted() {
    this.initFirestoreListener();
  },

  methods: {
    search() {
      // 沒有用到
      console.log("search:" + this.searchString);
      return;
      // this.searchString = _searchString;
      // this.$store.commit('setSearchString', _searchString);
    },

    convertToDateTime(ts) {
      if (ts) {
        // return moment.unix(ts).format('YYYY/MM/DD HH:mm:ss');
        return moment.unix(ts).format("HH:mm");
      }
      return "";
    },

    initFirestoreListener() {
      //WARNING: Should only be called after authenticated.
      console.log("[PushMessage] initFirestoreListener");
      // Define your Firestore collection (for example, "events")
      const self = this;
      const env = process.env.VUE_APP_AIR3_ENV
        ? process.env.VUE_APP_AIR3_ENV.trim()
        : "production";
      const topicId = `quickclick-${env}-${this.shopId}`;
      const eventsCollection = air3Firestore.collection(
        `notifications/quickclick/topics/${topicId}/messages`
      );

      // Create a query to only fetch events after the specific date
      let startDate = this.formattedDate[0];
      let endDate = this.formattedDate[1];

      const eventsQuery = eventsCollection
        .where("ts", ">=", startDate)
        .where("ts", "<=", endDate)
        .orderBy("ts", this.isChecked ? "asc" : "desc");

      this.unsubscribeFirestoreListener();

      this.snapshotListenerUnsubscriber = eventsQuery.onSnapshot((snapshot) => {
        self.messages = [];
        snapshot.forEach(function(doc, index) {
          let item = { ...doc.data(), read: false, expanded: true };
          const isRead = self.lastReadAt ? item.ts <= self.lastReadAt : true;
          item.read = isRead;
          self.messages.push(item);
        });

        // snapshot.docChanges().forEach(change => {

        // });
      });
    },

    unsubscribeFirestoreListener() {
      if (this.snapshotListenerUnsubscriber)
        this.snapshotListenerUnsubscriber();
    },

    signout() {
      // Disengage reminder loop.
      clearTimeout(this.reminderLoopTimeout);
      clearInterval(this.reminderLoopInterval);

      // Remove touchend/mouseup listener.
      document.removeEventListener("touchend", this.inactivityBreaker, false);
      document.removeEventListener("mouseup", this.inactivityBreaker, false);

      // Unsubscribe to Firestore collection changes.
      FirestoreDataEvent.$emit("unsubscribe-orders");

      // Request sign-out.
      this.$store
        .dispatch("signout")
        .then((response) => {
          console.log(`Sign-out succeeded.`, response.data);
        })
        .catch((err) => {
          console.error(`Sign-out failed`, err);
          alert("Sign-out failed.");
        })
        .finally(() => {
          setTimeout(() => {
            this.$router.push("/auth");
          }, 300);
        });
    },

    // Create the Intersection Observer
    createObserver() {
      console.log("createObserver");
      const scrollContainer = this.$refs.scrollContainer;
      if (!scrollContainer || !(scrollContainer instanceof HTMLElement)) {
        console.error(
          "Error: scrollContainer ref is not a valid HTMLElement:",
          scrollContainer
        );
        return;
      }

      const options = {
        root: scrollContainer, // viewport
        rootMargin: "0px",
        threshold: 0.5, // When 50% of the element is visible
      };

      const observer = new IntersectionObserver(
        this.handleIntersection,
        options
      );

      // Observe each list item
      this.$nextTick(() => {
        this.filteredMessages.forEach((_, index) => {
          const listItem = this.$refs[`listItem_${index}`];
          const listItemElement = listItem.length ? listItem[0].$el : null;
          // console.log("Observing listItemElement", listItemElement);
          if (listItemElement) {
            observer.observe(listItemElement); // Observing each item
          } else {
            console.warn(`Element with ref listItem_${index} not found.`);
          }
        });
      });
    },

    // Handle when an item intersects with the viewport
    handleIntersection(entries) {
      const self = this;
      entries.forEach(async (entry) => {
        const index = entry.target.dataset.index;
        const item = this.filteredMessages[index];
        // console.log(`-----handleIntersection ${index} ${entry.isIntersecting}`);
        if (entry.isIntersecting) {
          // 若透過此控制展開縮起，會出現一些非預期行為
          // self.$set(self.filteredMessages, index, { ...item, expanded: true });

          if (!item.read) {
            // Update the Firestore document to mark it as read
            // await db.collection('your-collection-name').doc(item.id).update({
            //   read: true,
            // });

            // Update the local state
            setTimeout(function() {
              // console.log(
              //   `----set index ${index} to read ---- ${moment().unix()}`
              // );
              self.$set(self.filteredMessages, index, { ...item, read: true });
            }, 300);
          }
        }
      });
    },

    scrollToFirstUnreadItem() {
      console.log("scrollToFirstUnreadItem");
      // Find the first unread item
      const firstUnreadItemIndex = this.filteredMessages.findIndex(
        (item) => !item.read
      );
      // If we find an unread item, scroll to it
      if (firstUnreadItemIndex !== -1) {
        const firstUnreadItem = this.$refs[`listItem_${firstUnreadItemIndex}`];
        const firstUnreadItemElement = firstUnreadItem.length
          ? firstUnreadItem[0].$el
          : null;

        if (firstUnreadItemElement) {
          // Scroll the item into view
          firstUnreadItemElement.scrollIntoView({
            behavior: "smooth",
            block: "start", // Align the item at the top of the container
          });
        }
      }
    },

    checkOverflow() {
      // Get the content container element
      const contentContainer = this.$refs.scrollContainer;
      // Check if the content is overflowing
      this.isOverflow =
        contentContainer?.scrollHeight > contentContainer?.clientHeight;
    },

    onFabClick() {
      // Get the content container element
      const contentContainer = this.$refs.scrollContainer;
      // Scroll to the bottom
      contentContainer.scrollTop = contentContainer.scrollHeight;
    },

    scrollToTop() {
      // Get the content container element
      const contentContainer = this.$refs.scrollContainer;
      // Scroll to the bottom
      contentContainer.scrollTop = 0;
    },
  },

  data() {
    return {
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      modal: false,
      nowDate: new Date().toISOString().slice(0, 10),
      searchString: "",
      snapshotListenerUnsubscriber: null,
      messages: [],
      isOverflow: false,
      lastReadAt: 1741771805,
      isChecked: false,
    };
  },

  computed: {
    startDate() {
      // const date = new Date();
      // var startDate = new Date(date.getFullYear(), date.getMonth() - 1, 10);
      const date = moment();
      var startDate = date.subtract(1, "months");
      return startDate.toISOString().slice(0, 10);
    },

    formattedDate() {
      return [
        moment(this.date, "YYYY-MM-DD")
          .startOf("day")
          .unix(),
        moment(this.date, "YYYY-MM-DD")
          .endOf("day")
          .unix(),
      ];
    },

    shopId() {
      return this.$store.state.shopId;
    },
    shopName() {
      return this.$store.state.shopName;
    },

    version() {
      return this.$store.state.version;
    },
    themeColor() {
      // return process.env.VUE_APP_PWA_THEME_COLOR;
      return process.env.VUE_APP_THEME_COLOR;
    },
    themeColorLighten() {
      return process.env.VUE_APP_PWA_THEME_COLOR_LIGHTEN;
    },

    filteredMessages() {
      if (!this.searchString) return this.messages;
      const searchString = this.searchString.toLowerCase();
      var filteredArray = this.messages.filter(function(item) {
        return (
          item.title.toLowerCase().includes(searchString) ||
          item.text.toLowerCase().includes(searchString)
        );
      });
      // if (filteredArray.length) filteredArray[0].expanded = true;
      return filteredArray;
    },
  },

  watch: {
    version(v) {
      console.warn(`Version change detected`, v);
      // if (process.env.PACKAGE_VERSION !== v) {
      //   alert(`有更新的版本可用（${v}），請按「確定」後執行登出，並重新登入以更新。`);
      // }
    },
    // filteredMessages() {
    //   this.$nextTick(() => {
    //     this.createObserver();
    //   });
    // },
    "filteredMessages.length"(newLength, oldLength) {
      this.$nextTick(() => {
        this.createObserver();
        this.checkOverflow();
        this.scrollToTop();
        // this.scrollToFirstUnreadItem();
      });
    },
  },
};
</script>

<style scoped>
/* html, body {
  height: 100vh;
} */
body {
  background: #efefef;
}

.main-container {
  height: 100vh;
  overflow-y: hidden;
}

.list-container {
  min-height: 50vh;
  max-height: calc(100vh - 250px);
  overflow-y: auto;
  padding-bottom: 20px;
}

.collapsible-content {
  max-height: 100px; /* Collapse the content by default */
  overflow: hidden;
  transition: max-height 0.5s ease; /* Smooth transition */
}

.collapsible-content.expanded {
  max-height: 100%; /* Expand content when in view */
}

.invisible-border-card {
  border: none !important; /* Remove the border */
  box-shadow: none !important; /* Remove the shadow */
  outline: none !important; /* Remove any outline */
}

/* Floating Action Button (FAB) positioned at the bottom-right */
.fab-button {
  position: fixed;
  right: 16px;
  bottom: 120px;
  z-index: 1000; /* Ensure it stays on top of other elements */
}

/* Transparent FAB Button */
.transparent-btn {
  background-color: transparent; /* Makes the button background transparent */
  border: 2px solid rgba(255, 255, 255, 0.7); /* Optional: Adds a semi-transparent border */
  box-shadow: none; /* Removes any box shadow */
}

.transparent-btn .v-icon {
  color: gray; /* Optional: Adjust icon color to match transparency */
}

.text-truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.normal-wrap {
  text-align: left; 
  display: inline-block; /* 设置 span 为 inline-block 使其支持换行 */
  word-wrap: break-word; /* 自动换行 */
  word-break: break-word; /* 强制长单词在容器内换行 */
  white-space: normal; /* 允许正常换行 */
}

.text-right {
  text-align: right; 
}
</style>
